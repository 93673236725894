import React from "react"

const NousJoindreComponent = () => {
  return (
    <>
      <span>a</span>
    </>
  )
}

export default NousJoindreComponent
