import React, { useState, useEffect } from "react"
import BaseLayout from "../components/core/BaseLayout"
import SEO from "../components/seo"
import NousJoindreComponent from "../components/core/nous-joindre/index.js"

const NousJoindre = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 0)
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      {isLoading ? (
        <span></span>
      ) : (
        <BaseLayout>
          <SEO title="nous-joindre" />
          <NousJoindreComponent />
        </BaseLayout>
      )}
    </>
  )
}

export default NousJoindre
